import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import { useEffect, useState } from "react";
import { ReactComponent as CaretUpIcon } from "../../Assets/caret-up.svg";
import { ReactComponent as CheckIcon } from "../../Assets/check-solid.svg";
import { ReactComponent as PlusIcon } from "../../Assets/plus-solid.svg";
import { ReactComponent as ChevronRightIcon } from "../../Assets/angle-right-solid.svg";
import { ReactComponent as BugIcon } from "../../Assets/bug-solid.svg";
import { ReactComponent as PaperclipIcon } from "../../Assets/paperclip-solid.svg";
import { Callout } from "../../Helper/Callout";
import Communicator from "../../Helper/Communicator";
import {
  contentEnhanceCode,
  contentWithVariables,
} from "../../Helper/ContentReplace";
import CustomLink from "../../Helper/CustomLink";
import { getDataDescription } from "../../Helper/FeedbackItemHelper";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import IFrame from "../../Helper/IFrame";
import { isOnline } from "../../Helper/OnlineState";
import { replaceAll } from "../../Helper/String";
import { useAppViewStore } from "../../Stores/AppView";
import { useConfigStore } from "../../Stores/Config";
import { useFeatureRequestStore } from "../../Stores/FeatureRequests";
import { useFormStore } from "../../Stores/Form";
import { useRouterStore } from "../../Stores/Router";
import { useSessionStore } from "../../Stores/Session";
import { Ticket, TicketChatMessage } from "../../Stores/Ticket";
import BotActionChatMessage from "../BotActionChatMessage/BotActionChatMessage";
import ChatAvatar from "../ChatAvatar/ChatAvatar";
import FeatureRequestSuggestions from "../FeatureRequestSuggestions/FeatureRequestSuggestions";
import { ratingOptions } from "../RatingFormItem/RatingFormItem";
import "./ChatMessage.scss";
import { ServerVariableNode } from "../../Helper/VariableExtension";
import ActionButton from "../AIActionButton/AIActionButton";
import LoadingText from "../LoadingText/LoadingText";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

export function replaceMarkdown(inputString: string): string {
  try {
    const linkRegex = /\[([^\]]+)\]\((https?|mailto|tel):([^)\s]+)\)/g;
    let replacedText = inputString.replace(
      linkRegex,
      '<a href="$2:$3" target="_blank">$1</a>'
    );

    // Replace bold text (**text** or __text__) with <strong> tags
    const boldRegex = /(\*\*|__)(.*?)\1/g;
    replacedText = replacedText.replace(boldRegex, "<strong>$2</strong>");

    // Replace italic text (*text* or _text_) with <em> tags
    const italicRegex = /(\s|^)(\*|_)([^\s].*?[^\s])\2(\s|$)/g;
    replacedText = replacedText.replace(italicRegex, "$1<em>$3</em>$4");

    return replacedText;
  } catch (e) {
    // Return the input string unchanged in case of an error
    return inputString;
  }
}

function ChatMessage({
  chatMessage,
  lastOfGroup = false,
  isCreator = false,
  showTime = true,
  typingDelay = 0,
  forceTyping = false,
  imageLoaded = undefined,
}: {
  chatMessage: TicketChatMessage;
  lastOfGroup?: boolean;
  isCreator?: boolean;
  showTime?: boolean;
  typingDelay?: number;
  forceTyping?: boolean;
  imageLoaded?: () => void;
}) {
  const configStore = useConfigStore();
  const [isTyping, setIsTyping] = useState(typingDelay > 0 ? true : false);

  useEffect(() => {
    var timer: any = null;
    if (typingDelay && typingDelay > 0) {
      timer = setTimeout(() => {
        setIsTyping(false);
      }, typingDelay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const openingTimeAutoResponderText = () => {
    const { online, nextOpeningTime, currentOpeningTime } = isOnline();

    var message = "";
    if (online) {
      let currentReplyTime =
        currentOpeningTime?.replyTime ?? configStore.config.replyTime;

      message = `${configStore.translateText(currentReplyTime)}.`;
    } else {
      message = `${configStore.translateText(
        "weAreOffline"
      )}\n\n${configStore.translateText("weAreBack")} <b>🕘 ${moment(
        nextOpeningTime
      ).fromNow()}</b>.`;
    }

    return message;
  };

  const renderContent = () => {
    if (isTyping || forceTyping) {
      return <div className="dot-flashing" />;
    }

    if (chatMessage.type === "FEATURE_REQUEST_DUPLICATES") {
      return <FeatureRequestSuggestions />;
    }

    if (chatMessage?.data?.answerBot && !chatMessage.data?.hasAnswer) {
      return (
        <div className="message-text">
          {configStore.translateText("kaiIssue")}
        </div>
      );
    }

    if (
      chatMessage.type === "TEXT" ||
      chatMessage.type === "USER_TEXT" ||
      chatMessage.type === "SHARED_COMMENT" ||
      chatMessage.type === "EMAIL_MESSAGE" ||
      chatMessage.type === "BOT"
    ) {
      // Special message for reply times.
      if (chatMessage.data?.showReplyTimes) {
        var openingTime = openingTimeAutoResponderText();
        if (!openingTime) {
          return null;
        }

        return (
          <div
            className="message-text"
            dangerouslySetInnerHTML={{ __html: openingTime }}
          ></div>
        );
      }

      // Special message for scheduled events.
      if (chatMessage.data?.scheduledMeetingData) {
        const { scheduledMeetingData } = chatMessage.data;

        return (
          <div className="message-text">
            <h3 className="scheduled-title">
              {configStore.translateText("scheduled")}:{" "}
              {scheduledMeetingData.name}
            </h3>
            <div className="scheduled-date">
              {new Date(scheduledMeetingData?.startTime).toLocaleDateString(
                navigator.language,
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </div>
            {scheduledMeetingData?.user && (
              <div className="scheduled-user">
                {scheduledMeetingData?.user?.name} (
                <a href={`mailto:${scheduledMeetingData?.user?.email}`}>
                  {scheduledMeetingData?.user?.email}
                </a>
                )
              </div>
            )}
          </div>
        );
      }

      var contentData = chatMessage?.data?.content;

      var content: any = "";
      if (contentData) {
        if (
          contentData &&
          (typeof contentData === "string" || contentData instanceof String)
        ) {
          // If it's a plain text message, we need to enhance it with code blocks.
          content = contentEnhanceCode(`${contentData}`);

          content = replaceAll(content, "\n\n", "\n");
          content = replaceAll(content, "\n", "<br>");
        } else {
          if (chatMessage.data.content) {
            try {
              content = generateHTML(chatMessage.data.content, [
                StarterKit.configure({ codeBlock: {}, heading: false }),
                Heading.configure({
                  levels: [1, 2, 3],
                }),
                Mention,
                Placeholder,
                CustomLink.configure({
                  protocols: ["http", "https", "mailto", "tel", "gleap"],
                }),
                Image,
                TextStyle,
                ServerVariableNode,
                Youtube.configure({ controls: true }),
                IFrame,
                Callout,
                HelpCenterArticleExtension,
                Color.configure({
                  types: ["textStyle"],
                }),
              ]);

              let regex = /<helpcenterarticle.*?>.*?<\/helpcenterarticle>/gs;
              let matches = Array.from(content.matchAll(regex));

              for (let i = 0; i < matches.length; i++) {
                let match: any = matches[i];
                let textToReplace = match[0];

                // perform the necessary replacements on textToReplace
                let replacedText = replaceAll(textToReplace, "&lt;", "<");
                replacedText = replaceAll(replacedText, "&gt;", ">");
                replacedText = replaceAll(replacedText, "&quot;", '"');
                replacedText = replaceAll(
                  replacedText,
                  "helpcenterarticle",
                  "div"
                );
                // replace the original text with the modified text
                content = content.replace(textToReplace, replacedText);
              }

              content = replaceAll(content, "\n", "<br>");
            } catch (err) {
              console.error(err);
            }
          }
        }
      } else if (
        chatMessage?.data?.text &&
        (typeof chatMessage.data.text === "string" ||
          chatMessage.data.text instanceof String)
      ) {
        // Replace new lines.
        content = replaceAll(chatMessage.data.text, "\n", "<br>");
      }

      content = contentWithVariables(content, {
        name: useSessionStore.getState().getName(),
      });

      content = replaceMarkdown(content);

      return (
        <div
          className="message-text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      );
    }
    if (chatMessage.type === "BOT_REPLY") {
      let message = chatMessage?.data?.content;

      if (Array.isArray(message)) {
        message = message.join(", ");
      } else if (typeof message === "object") {
        message = JSON.stringify(message);
      }

      return <div className="message-text">{message}</div>;
    }
    if (chatMessage.type === "FEEDBACK_ITEM") {
      const feedbackItem = chatMessage.data as Ticket;
      if (feedbackItem && feedbackItem.form) {
        const formKeys = Object.keys(feedbackItem.form);
        return (
          <div>
            {feedbackItem.type === "FEATURE_REQUEST" && (
              <strong>{feedbackItem.title}</strong>
            )}
            {formKeys.map((formKey) => {
              const formItem = feedbackItem.form[formKey];

              let value = formItem.value;

              if (formItem.type === "multiplechoice") {
                value =
                  formItem?.choices?.find(
                    (choice: any) => choice?.id === formItem.value
                  )?.label ?? "--";
              }

              if (
                formItem.type === "multiple-choice-multi-select" &&
                Array.isArray(formItem.value)
              ) {
                const labels = formItem.value
                  .map((val: string) => {
                    const choice = formItem?.choices?.find(
                      (choice: any) => choice && choice?.id === val
                    );
                    return choice ? choice.label : null;
                  })
                  .filter(Boolean);

                value = labels.join(", ") || "--";
              }

              return (
                <div key={formKey} className="message-form-item">
                  <div className="message-form-item-value">{value}</div>
                </div>
              );
            })}
          </div>
        );
      }

      const content = getDataDescription({
        data: feedbackItem,
        maxLength: 10000,
      });
      if (content === "No content") {
        return null;
      }

      return <div className="message-text">{content}</div>;
    }

    return null;
  };

  const renderAdditionalContent = () => {
    if (chatMessage.type === "FEEDBACK_ITEM") {
      const feedbackItem = chatMessage.data as Ticket;

      if (feedbackItem && feedbackItem.screenshotUrl) {
        return (
          <div className="chat-message-image">
            <img
              src={feedbackItem.screenshotUrl}
              onLoad={() => {
                if (imageLoaded) {
                  imageLoaded();
                }
              }}
              onClick={() => {
                Communicator.openImage(feedbackItem.screenshotUrl || "");
              }}
            />
          </div>
        );
      }
    }

    return null;
  };

  const isFileImage = (file: any) => {
    return file && file["type"].split("/")[0] === "image";
  };

  if (chatMessage.type === "BOT_INPUT_REPLY") {
    return (
      <BotActionChatMessage
        date={chatMessage.createdAt}
        hideAvatar={!lastOfGroup}
      >
        <div className="bot-input-reply">
          {chatMessage?.data?.type === "rateconversation" ? (
            <>
              <div className="bot-input-reply-label">
                {chatMessage?.data?.question}
              </div>
              <div className="bot-input-reply-rating-response">
                {[1, 2.5, 5, 7.5, 10].map((value: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`bot-input-reply-rating-response-option ${
                        chatMessage?.data?.rating === value &&
                        "bot-input-reply-rating-response-option--selected"
                      }`}
                    >
                      {ratingOptions[index]}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="bot-input-reply-label">
                {chatMessage?.data?.question}
              </div>
              <div className="bot-input-reply-response">
                {chatMessage?.data?.content}
              </div>
              <CheckIcon className="bot-input-reply-check" />
            </>
          )}
        </div>
      </BotActionChatMessage>
    );
  }

  const content = renderContent();
  if (!content) {
    return null;
  }

  const messageFromKai = !(
    chatMessage?.data?.profileImageUrl ||
    configStore.config.operatorAvatarImage?.length
  );

  const renderCustomActions = () => {
    if (
      !chatMessage.data?.buttonActions ||
      chatMessage.data?.buttonActions.length === 0
    ) {
      return null;
    }

    return (
      <div className="ai-action-buttons">
        {chatMessage.data?.buttonActions.map((action: any, index: number) => {
          return <ActionButton key={index} action={action} index={index} />;
        })}
      </div>
    );
  };

  const customActions = renderCustomActions();

  return (
    <div
      className={`chat-message ${
        chatMessage.type === "FEATURE_REQUEST_DUPLICATES" &&
        "chat-message--featurerequest-duplicates"
      } ${customActions && "chat-message-ai-action"} ${
        forceTyping && "chat-message--typing"
      } ${
        (chatMessage.data?.answerBot || messageFromKai) && "chat-message--aibot"
      } ${isCreator && "chat-message--creator"} ${
        lastOfGroup && "chat-message--last-of-group"
      } ${chatMessage?.data?.isWritting && "chat-message--writting"}`}
    >
      <ChatAvatar user={chatMessage.user} session={chatMessage.session} />
      <div className="message-container">
        <div className="message">
          {chatMessage?.data?.isWritting ? (
            <LoadingText
              text={chatMessage?.data?.content ?? "I'm working on it..."}
            ></LoadingText>
          ) : (
            <>
              {content}
              {customActions}
              {!customActions &&
                chatMessage.data?.sources &&
                chatMessage.data?.sources.length > 0 && (
                  <>
                    <div className="link-sources">
                      <div className={`link-sources-links`}>
                        {chatMessage.data?.sources.map(
                          (source: any, index: number) => {
                            const onClick = () => {
                              if (source.type === "featurerequest") {
                                useFeatureRequestStore
                                  .getState()
                                  .openFeatureRequest(source.shareToken, true);
                              } else if (
                                source.type === "createfeaturerequest"
                              ) {
                                const flow = "featurerequests";
                                useFormStore.setState({
                                  feedbackFlow: flow,
                                  isSurveyFlow: false,
                                  preFillData: {
                                    description:
                                      source?.data?.description ?? "",
                                    title: source?.data?.title ?? "",
                                  },
                                });
                                useRouterStore.getState().pushPage("flow", {
                                  flowId: flow,
                                });
                                useAppViewStore.getState().reset();
                              } else if (source.type === "reportabug") {
                                const flow = "bugreporting";
                                useFormStore.setState({
                                  feedbackFlow: flow,
                                  isSurveyFlow: false,
                                  preFillData: {
                                    description:
                                      source?.data?.description ?? "",
                                  },
                                });
                                useRouterStore.getState().pushPage("flow", {
                                  flowId: flow,
                                });
                                useAppViewStore.getState().reset();
                              } else {
                                Communicator.openURL(source.url, true);
                              }
                            };

                            if (source.type === "createfeaturerequest") {
                              return (
                                <div
                                  key={index}
                                  className="link-source link-source-button"
                                  onClick={onClick}
                                >
                                  <div className="link-source--submiticon">
                                    <PlusIcon />
                                  </div>
                                  <div className="link-source--title">
                                    {source.title}
                                  </div>
                                  <ChevronRightIcon className="link-source--arrow" />
                                </div>
                              );
                            }

                            if (source.type === "reportabug") {
                              return (
                                <div
                                  key={index}
                                  className="link-source link-source-button"
                                  onClick={onClick}
                                >
                                  <div className="link-source--submiticon">
                                    <BugIcon />
                                  </div>
                                  <div className="link-source--title">
                                    {source.title}
                                  </div>
                                  <ChevronRightIcon className="link-source--arrow" />
                                </div>
                              );
                            }

                            if (!(configStore.config.showSources ?? true)) {
                              return;
                            }

                            return (
                              <div
                                key={index}
                                className={`link-source ${
                                  (source.type === "featurerequest" ||
                                    source.type === "createfeaturerequest") &&
                                  "link-source-button"
                                }`}
                                onClick={onClick}
                              >
                                {source.type === "featurerequest" && (
                                  <div className="link-source--upvotes">
                                    <CaretUpIcon />
                                    {source.upvotes > 99
                                      ? "99+"
                                      : source.upvotes}
                                  </div>
                                )}
                                {source.type === "createfeaturerequest" && (
                                  <div className="link-source--submiticon">
                                    <PlusIcon />
                                  </div>
                                )}
                                <div className="link-source--title">
                                  {source.title}
                                </div>
                                <ChevronRightIcon className="link-source--arrow" />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              {chatMessage.attachments &&
                chatMessage.attachments.map((attachment, index) => {
                  if (isFileImage(attachment)) {
                    return (
                      <img
                        className="attachment-image"
                        src={attachment.url}
                        alt={attachment.name}
                        onLoad={() => {
                          if (imageLoaded) {
                            imageLoaded();
                          }
                        }}
                        onClick={() => {
                          if (attachment.url) {
                            if (configStore.isApp) {
                              Communicator.openURL(attachment.url);
                            } else {
                              Communicator.openImage(attachment.url);
                            }
                          }
                        }}
                      />
                    );
                  }

                  // Check if the attachment is an audio file
                  if (attachment.type.startsWith("audio/")) {
                    return (
                      <AudioPlayer key={index} audioSrc={attachment.url} />
                    );
                  }

                  return (
                    <div
                      className="attachment"
                      key={index}
                      onClick={() => {
                        if (configStore.isApp) {
                          Communicator.openURL(attachment.url);
                        } else {
                          var xhr = new XMLHttpRequest();
                          xhr.open("GET", attachment.url);
                          xhr.responseType = "blob";
                          xhr.onload = function () {
                            if (!xhr.response) {
                              return;
                            }

                            var blob = xhr.response;
                            const url = window.URL.createObjectURL(blob);

                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", attachment.name);
                            document.body.appendChild(link);

                            link.click();
                            link.remove();
                          };
                          xhr.send();
                        }
                      }}
                    >
                      <a className="attachment-link">
                        <PaperclipIcon />
                        {attachment.name}
                      </a>
                    </div>
                  );
                })}
            </>
          )}
          {chatMessage?.data?.answerBot && (
            <>
              <div className="answer-bot">
                <div className="answer-bot-tag">AI</div>{" "}
                {configStore.translateText("kaiAnswerTag")}
              </div>
            </>
          )}
        </div>
        {renderAdditionalContent()}
        {showTime && (
          <div className="message-date">
            {(chatMessage.type === "BOT" || chatMessage.data?.answerBot) &&
              `${configStore.getBotName()} · `}
            {chatMessage.sending
              ? `Sending...`
              : moment(chatMessage.createdAt ?? new Date()).fromNow()}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;
