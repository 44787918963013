import { useConfigStore } from "../Stores/Config";
import { useSessionStore } from "../Stores/Session";
import { useFormStore } from "../Stores/Form";
import { AppMode, useRouterStore } from "../Stores/Router";
import { useWebSocketStore } from "../Stores/WebSocket";
import { useTicketStore } from "../Stores/Ticket";
import { useNewsStore } from "../Stores/News";
import { useFeatureRequestStore } from "../Stores/FeatureRequests";
import { useAppViewStore } from "../Stores/AppView";
import { useHelpCenterStore } from "../Stores/HelpCenter";
import Communicator from "./Communicator";
import { resetWidget } from "../Components/WidgetHeader/WidgetHeader";
import { useChecklistStore } from "../Stores/Checklist";

const FORM_SENT_TIMEOUT = 3000;
var closeOnSendTimeout: any = null;

const createRecaptcha = function () {
  let script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("defer", "");
  script.id = "recaptchaScript";
  script.src =
    "https://www.recaptcha.net/recaptcha/api.js?render=6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT";
  document.getElementsByTagName("head")[0].appendChild(script);
};

export default class CommunicationManager {
  private static _instance: CommunicationManager;
  private static injectedScript: boolean = false;
  private static isWidgetOpen: boolean = false;
  private resolvers = new Map();

  static getInstance() {
    if (!this._instance) {
      this._instance = new CommunicationManager();
    }
    return this._instance;
  }

  constructor() {
    this.startMessageListener();
  }

  sendMessageWithResolver(message: { name: string; data?: any }) {
    return new Promise((resolve, reject) => {
      // Store the resolve function in the map
      this.resolvers.set(message.name, resolve);

      this.sendMessage(message);

      // Set a timeout for the message
      setTimeout(() => {
        if (this.resolvers.has(message.name)) {
          this.resolvers.delete(message.name);
          reject(new Error("Message timeout"));
        }
      }, 800);
    });
  }

  sendMessage(data: any) {
    if (data && data.name === "close-widget" && closeOnSendTimeout) {
      clearTimeout(closeOnSendTimeout);
      closeOnSendTimeout = null;
    }

    var parentWindow = (window as any).top;
    if ((window as any).parent != (window as any).top) {
      parentWindow = (window as any).parent;
    }

    if (parentWindow) {
      parentWindow.postMessage(JSON.stringify(data), "*");
    }
  }

  reloadMainData() {
    useTicketStore.getState().silentRefreshTickets();

    if (useTicketStore.getState().currentTicket) {
      try {
        useTicketStore.setState({
          botWrittingText: undefined,
        });
        useTicketStore.getState().loadComments(true);
      } catch (exp) {}
    }
  }

  reconnect() {
    useWebSocketStore.getState().connect(() => {
      this.reloadMainData();
    });
  }

  disconnect() {
    useWebSocketStore.getState().disconnect();
  }

  processAction(data: any) {
    if (data.data && data.data.hideBackButton) {
      useRouterStore.setState({
        showBackButton: false,
      });
    }

    if (data.name === "start-feedbackflow") {
      useFormStore.setState({
        feedbackFlow: data.data.flow,
        isSurveyFlow: false,
      });
      useRouterStore.getState().pushPage("flow", {
        flowId: data.data.flow,
      });
      useAppViewStore.getState().reset();
    }
    if (data.name === "start-survey") {
      useFormStore.setState({
        feedbackFlow: data.data.flow,
        isSurveyFlow: true,
      });
      useRouterStore.getState().setPage(
        "flow",
        {
          flowId: data.data.flow,
        },
        data.data.format
      );
      useAppViewStore.getState().reset();
    }
    if (data.name === "start-bot") {
      useTicketStore.getState().createNewConversation({
        botId: data.data.botId,
      });
    }
    if (data.name === "open-home") {
      useRouterStore.getState().setPage("menu");
    }
    if (data.name === "open-conversations") {
      useRouterStore.getState().pushPage("conversations");

      // Reload.
      useTicketStore.getState().loadConversations();
    }
    if (data.name === "open-conversation") {
      useRouterStore.getState().pushPage("conversation", {
        shareToken: data.data.shareToken,
      });

      // Reload.
      const currentTicket = useTicketStore.getState().currentTicket;
      if (currentTicket) {
        useTicketStore.getState().loadComments(true);
      }
    }
    if (data.name === "open-news-article") {
      useRouterStore.getState().pushPage("newsdetails", {
        id: data.data.id,
      });
    }
    if (data.name === "open-checklists") {
      useRouterStore.getState().setPage("checklists", {
        id: data.data.id,
      });
    }
    if (data.name === "open-checklist") {
      useRouterStore.getState().pushPage("checklistdetails", {
        id: data.data.id,
      });
    }
    if (data.name === "start-checklist") {
      useRouterStore.getState().setPage("checklists");
      useChecklistStore.getState().startChecklist(data.data.outboundId);
    }
    if (data.name === "open-news") {
      useRouterStore.getState().setPage("news");
    }
    if (data.name === "open-helpcenter") {
      useHelpCenterStore.getState().open(data.data.pushToHistory ?? false);
    }
    if (data.name === "open-helpcenter-search") {
      useHelpCenterStore
        .getState()
        .searchHelpCenter(data.data.term, data.data.pushToHistory ?? false);
    }
    if (data.name === "open-help-collection") {
      useHelpCenterStore
        .getState()
        .openCollection(
          data.data.collectionId,
          data.data.pushToHistory ?? false
        );
    }
    if (data.name === "open-help-article") {
      useHelpCenterStore
        .getState()
        .openArticle(data.data.articleId, data.data.pushToHistory ?? false);
    }
    if (data.name === "open-feature-requests") {
      useFeatureRequestStore.getState().open();
    }
  }

  startMessageListener() {
    try {
      // Manage ticket updates when the widget is in the background and then comes back to the foreground.
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          const ticketStore = useTicketStore.getState();
          if (ticketStore.currentTicket?.id) {
            const comments = ticketStore.currentComments;
            if (comments) {
              for (let i = 0; i < comments.length; i++) {
                const comment = comments[i];
                if (comment.id && comment?.sessionNotificationsUnread) {
                  useTicketStore.setState({
                    botWrittingText: undefined,
                    canSetBotWrittingText: false,
                  });
                  ticketStore.clearUserTyping();
                  ticketStore.loadComment(
                    comment.id,
                    ticketStore.currentTicket?.id
                  );
                }
              }
            }
          }
        }
      });
    } catch (exp) {}

    // Add window message listener.
    window.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);

        // If the message has a resolver, resolve it.
        const resolve = this.resolvers.get(data.name);
        if (resolve) {
          resolve(data.data);
          this.resolvers.delete(data.name);
        }

        if (data.type === "app") {
          if (data.name === "page") {
            useAppViewStore
              .getState()
              .pushPage(data.data.title, data.data.extendScreen);
          }
          if (data.name === "pagetitle") {
            useAppViewStore
              .getState()
              .replacePageTitle(data.data.title, data.data.extendScreen);
          }
          if (data.name === "data") {
            useAppViewStore.getState().setAppData(data.data);
          }
          if (data.name === "open-url") {
            Communicator.openURL(data.data, data.newTab ? true : false);
          }
          if (data.name === "show-app-scroll-title") {
            useAppViewStore
              .getState()
              .setShowExtendedViewTitle(data.data?.show ? true : false);
          }
        }

        if (data.name === "config-update") {
          if (data.data.config) {
            useConfigStore.getState().setConfig(data.data.config);

            if (
              data.data.config &&
              data.data.config.spamProtection &&
              !CommunicationManager.injectedScript
            ) {
              CommunicationManager.injectedScript = true;
              createRecaptcha();
            }
          }
          if (data.data.isApp) {
            useConfigStore.setState({
              isApp: true,
            });
          }
          if (data.data.aiTools && data.data.aiTools.length > 0) {
            useConfigStore.getState().setAiTools(data.data.aiTools);
          }
          if (data.data.overrideLanguage) {
            useConfigStore
              .getState()
              .setOverrideLanguage(data.data.overrideLanguage);
          }
        }
        if (data.name === "session-update") {
          var url = data.data.apiUrl;
          if (url === "https://api.gleap.io") {
            url = "https://dashapi.gleap.io";
          }

          useSessionStore.setState({
            apiUrl: url,
            sdkKey: data.data.sdkKey,
          });

          useSessionStore.getState().setSession(data.data.sessionData);
        }
        if (data.name === "widget-status-update") {
          CommunicationManager.isWidgetOpen = data.data.isWidgetOpen
            ? true
            : false;
          if (data.data.isWidgetOpen) {
            this.reconnect();
            useTicketStore.getState().loadConversations();
            useNewsStore.getState().loadNews();
            useConfigStore.getState().loadTeamInfo();
            useConfigStore.getState().loadHomeWidgets();
            useChecklistStore.getState().loadChecklists();
            useHelpCenterStore.getState().loadTopArticles();
          } else {
            this.disconnect();

            // Reset when widget is closed in news or app view extended views.
            if (
              useAppViewStore.getState().extendedView ||
              useRouterStore.getState().currentPage === "newsdetails"
            ) {
              resetWidget();
            }

            // Reset show back button.
            useRouterStore.setState({
              showBackButton: true,
            });

            // Check for active survey.
            if (useRouterStore.getState().appMode !== "widget") {
              useRouterStore.getState().setPage("menu");
              useFormStore.getState().resetForm();
            }
          }
        }
        if (data.name === "session-cleared") {
          try {
            localStorage.clear();
          } catch (exp) {}
        }
        if (data.name === "prefill-form-data") {
          useFormStore.getState().setPreFillFormData(data.data);
        }
        if (data.name === "feedback-sent") {
          useFormStore.getState().setFormSent();

          let formSentTimeout =
            useConfigStore.getState().config.thankYouDuration ||
            FORM_SENT_TIMEOUT;

          const formAction = useFormStore.getState().action;
          closeOnSendTimeout = setTimeout(() => {
            closeOnSendTimeout = null;

            if (useRouterStore.getState().appMode === AppMode.SURVEY_WEB) {
              // Don't close the widget in survey web mode.
              return;
            }

            if (
              formAction &&
              formAction.showConversation &&
              data &&
              data.data &&
              data.data.shareToken
            ) {
              useTicketStore.getState().loadConversations();
              useRouterStore.getState().pushPage("conversation", {
                shareToken: data.data.shareToken,
              });
              CommunicationManager.getInstance().sendMessage({
                name: "cleanup-drawings",
              });
            } else {
              this.sendMessage({
                name: "close-widget",
              });
              useRouterStore.getState().setPage("menu");
              useRouterStore.setState({ showBackButton: true });
            }
            useFormStore.getState().clearFeedbackFlowOptions();
          }, formSentTimeout);
        }
        if (data.name === "feedback-sending-failed") {
          useFormStore.setState({
            formSent: false,
            sendingForm: false,
            formError: data.data,
          });
        }
        if (data.name === "set-form-data") {
          useFormStore
            .getState()
            .setFormData(data.data.formKey, data.data.data);
        }
        this.processAction(data);
      } catch (exp) {}
    });
  }
}
