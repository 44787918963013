import { useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/arrow-up-solid.svg";
import { useConfigStore } from "../../Stores/Config";
import "./AnswerBotChatMessageComposer.scss";
import ImageResizerPicker from "../ImageResizePicker/ImageResizePicker";

export const defaultAttachmentMessage = "📎 Attachment";

function AnswerBotChatMessageComposer({
  sendMessage,
  onHeightUpdated,
  disabled = false,
}: {
  sendMessage: (
    message: string,
    file?: {
      url: string;
      name: string;
      type: string;
    }
  ) => void;
  onHeightUpdated: (height: number) => void;
  disabled?: boolean;
}) {
  const config = useConfigStore();
  const textareaRef = useRef(null as any);
  const [currentValue, setCurrentValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null as any);

  const isDisabled = disabled || !(currentValue && currentValue.length > 0);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      var scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight < 50) {
        scrollHeight = 50;
      }

      textareaRef.current.style.height = scrollHeight + "px";
      onHeightUpdated(scrollHeight);
    }
  }, [currentValue]);

  const internalSendMessage = () => {
    if (isDisabled) {
      return;
    }

    sendMessage(
      currentValue && currentValue.length > 0
        ? currentValue
        : defaultAttachmentMessage,
      selectedFile
    );
    setSelectedFile(null);
    setCurrentValue("");
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      internalSendMessage();
    }
  };

  const renderAttachment = () => {
    if (config.config?.vision) {
      return (
        <ImageResizerPicker
          onFileSelect={(file) => {
            setSelectedFile(file);
          }}
          onFileRemove={() => {
            setSelectedFile(null);
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      <div className="chat-message-composer-bg"></div>
      <div className="chat-message-composer">
        <textarea
          value={currentValue}
          placeholder={config.translateText("writeReply")}
          ref={textareaRef}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          onKeyDown={onEnterPress}
        />
        <div className="composer-buttons">
          {renderAttachment()}
          <div
            onClick={() => {
              internalSendMessage();
            }}
            className={`send-button ${isDisabled && `send-button--disabled`} `}
          >
            <SendLogo />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnswerBotChatMessageComposer;
