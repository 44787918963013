import React from "react";
import "./AudioPlayer.scss";

interface AudioPlayerProps {
  audioSrc: string;
}

const AudioPlayer = ({ audioSrc }: AudioPlayerProps) => {
  return <audio className="audio-player" controls src={audioSrc}></audio>;
};

export default AudioPlayer;
